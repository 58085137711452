const data = {
  Es: {
    ips: [
      {
        id: 0,
        section_title: "Instituciones Prestadoras de Servicios",
        title: "ANALICEMOS LABORATORIO CLÍNICO ESPECIALIZADO",
        description:
          "Institución prestadora de salud con personal altamente calificado en el área asistencial. Con más de 12 años de experiencia en procesamiento de muestras de origen biológico en equipos de tecnología de punta. Se caracteriza por su profesionalismo y actualización constante en nuevas técnicas de laboratorio.",
        image: "/assets/ips/ANALICEMOS.png",
        web: "https://analicemoslaboratorio.com/",
        professionals: [
          {
            id: 0,
            name: "Dr. ALBERTO BONNET ARCINIEGAS",
            title: "Urología",
            description:
              "Urólogo con Certificación en Urología Reconstructiva. Miembro de la Sociedad Colombiana de Urología y de la  Confederación Americana de Urología (CAU)",
            image: "/assets/icono-doctor.png",
          },
          {
            id: 1,
            name: "Dr. ALBERTO BONNET ARCINIEGAS",
            title: "Urología",
            description:
              "Urólogo con Certificación en Urología Reconstructiva. Miembro de la Sociedad Colombiana de Urología y de la  Confederación Americana de Urología (CAU)",
            image: "/assets/icono-doctor.png",
          },
          {
            id: 2,
            name: "Dr. ALBERTO BONNET ARCINIEGAS",
            title: "Urología",
            description:
              "Urólogo con Certificación en Urología Reconstructiva. Miembro de la Sociedad Colombiana de Urología y de la  Confederación Americana de Urología (CAU)",
            image: "/assets/icono-doctor.png",
          },
        ],
      },
      {
        id: 1,
        title: "CLÍNICA TOLIMA",
        description:
          "La Clínica Tolima S.A es una institución de tradición en el departamento del Tolima, que presta servicios de salud de mediana y alta complejidad, con un profundo respeto por el ser humano y su entorno. Basado en los principios corporativos, como la seguridad y la humanización del servicio, consiguiendo que las atenciones en cirugía bariátrica y estética, cumplan con las expectativas de quienes confían su salud a nuestros servicios.",
        image: "/assets/ips/CLINICA-TOLIMA.png",
        web: "https://clinicatolima.com/",
      },
      {
        id: 2,
        title: "CLÍNICA UNICAT",
        description:
          "La UNIDAD DE CIRUGÍA DEL TOLIMA S.A  presta servicios de salud quirúrgicos y hospitalarios amables, oportunos, ágiles y eficientes enmarcados en una atención segura, con desarrollo científico, con un equipo humano capacitado, comprometido; con ética y que como principio fundamental brinda lealtad y confidencialidad a las personas.",
        image: "/assets/ips/UNICAT.png",
        web: "https://clinicaunicat.com/",
      },
      {
        id: 3,
        title2:
          "COOPERATIVA ESPECIALIZADA DE SERVICIOS UROLÓGICOS DEL TOLIMA - UROTOL",
        title: "UROTOL",
        description:
          "Institución prestadora de servicios de salud, especializada en Urología, cuenta con servicios en diagnóstico, tratamiento médico, quirúrgico y exámenes especializados en la ciudad de Ibagué, contribuye al mejoramiento de la calidad de vida de sus pacientes, con una atención humanizada, brindando servicios de excelente calidad, actualizando y renovando constantemente la tecnología y los conocimientos de todo el talento humano de la organización.",
        image: "/assets/ips/UROTOL.png",
        web: "https://urotol.co/",
      },
      {
        id: 4,
        title: "DENTO-MAN S.A.S",
        description:
          "Institución prestadora de salud enfocada en brindar un servicio odontológico integral y especializado a través de un equipo de trabajo altamente capacitado. Con la implementación de tecnología adecuada, y, en un ambiente único y relajante, lograr en cada uno de los procedimientos odontológicos los más altos estándares de calidad y bioseguridad, haciendo de cada consulta una experiencia agradable, gratificante y positiva.",
        image: "/assets/ips/DENTOMAN.png",
        web: "https://dentoman.wixsite.com/dentoman",
      },
      {
        id: 5,
        title: "HELISCAN",
        description:
          "Empresa prestadora de servicios médicos en imágenes diagnósticas con 28 ñaos de experiencia, creada para ofrecer, apoyar y satisfacer las necesidades de la comunidad, de los profesionales médicos y de toda entidad relacionada con la prestación en servicios de salud. Los principales servicios son: Mamografía, ecografía, tomografía y rayos x.",
        image: "/assets/ips/HELISCAN.png",
        web: "https://detectarips.com/",
      },
      {
        id: 6,
        title: "INSTITUTO CARDIOVASCULAR DEL TOLIMA",
        description:
          "Creado para el manejo integral de servicios de cardiología destacando la integralidad en la prestación del servicio. Suministros, dispositivos y equipos de alta tecnología. Ofrece servicios de: Terapias de rehabilitación cardiaca, Reprogramación de Marcapaso, Cardioresincronizador y Cardiodesfibrilador, Prueba de Mesa Basculante, Monitoreo de Presión Arterial Sistémica, Electrofisiología, Electrocardiograma de Ritmo o de Superficie, Electrocardiografía Dinámica, Ecocardiograma Transesofágico, Consulta de Neuroradiología, entre otros.",
        image: "/assets/ips/INSTITUTO-CARDIOVASCULAR-DEL-TOLIMA.png",
        web: "https://inscardiovasculartolima.com/",
      },
      {
        id: 7,
        title: "IPS INTEGRAL SOMOS SALUD SAS",
        description:
          "Institución orientada a contribuir en el mejoramiento de la calidad de vida de los usuarios, que cuenta con profesionales idóneos y comprometidos, que trabajan de manera continua con eficiencia, responsabilidad y calidez humana, los servicios son prestados con equipos de alta tecnología y una infraestructura que cumple con los estándares de calidad. Sus principales servicios son:  Fisioterapia, terapia respiratoria, terapia ocupacional, terapia lenguaje y/o fonoaudiología, nutrición, psicología, audiología, espirometría.",
        image: "/assets/ips/SOMOS-SALUD.png",
        web: "https://somossalud.co/",
      },
      {
        id: 8,
        title: "MEDICADIZ",
        description:
          "Institución privada que ofrece servicios de salud de alta complejidad brindando atención humanizada, comprometida con el mejoramiento continuo de la calidad y seguridad del paciente, contribuyendo al bienestar de éstos, sus familias, colaboradores, inversionistas y la comunidad.",
        image: "/assets/ips/MEDICADIZ.png",
        web: "https://medicadiz.co/",
      },
      {
        id: 9,
        title: "ONCOMEDIC LTDA",
        description:
          "Ips de carácter privado creada desde 1994 para la atención del paciente oncológico. Brinda servicios con trato humano, seguro, oportuno, eficiente y eficaz mediante el ejercicio de personal capacitado articulado con procesos de innovación. La calidad de vida y la prevención es un diferencial fundamental en la organización, el respeto por el individuo y sus procesos la ayuda en la reconstrucción de la vida.",
        image: "/assets/ips/ONCOMEDIC.png",
        web: "https://oncomedic.co/",
      },
      {
        id: 10,
        title: "OPTICAS ORSOVISION S.A.S",
        description:
          "Institución prestadora de servicios de salud visual, con los mayores estándares de calidad, la más alta tecnología científica y con profesionales calificados en beneficio de nuestros usuarios y de la excelente imagen de las entidades aliadas. Con 36 años de experiencia. Sus principales servicios son: Consulta de Optometría, Ortóptica y pleóptica, Tamizajes visuales, Adaptación de lentes de contacto, Elaboración y Adaptación de Prótesis, Visiometrías ocupacionales en las diferentes empresas.",
        image: "/assets/ips/ORSOVISION.png",
        web: "https://www.orsovisionopticas.com/",
      },
      {
        id: 11,
        title: "OSTEOPOR",
        description:
          "Empresa prestadora de servicios médicos relacionado con imágenes diagnósticas y exámenes de ayuda diagnóstica con 25 años de experiencia. El mayor interés, es el apoyo diagnóstico de las enfermedades de personas adultas y de las de edad avanzada.   Actualmente líderes en el diagnóstico de la Osteoporosis. Sus principales servicios son: Densitometría ósea, Morfometría, Composición corporal, Terapias de ondas de choque.",
        image: "/assets/ips/OSTEOPOR.png",
        web: "https://osteoporltda.com/",
      },
      {
        id: 12,
        title: "PROMOVER SAS",
        description:
          "Institución prestadora de servicios de salud con 23 años de tradición en el país. Dentro de los servicios que presta en las diferentes sedes están el de ambulancias a través del Transporte asistencial básico, Tab y Medicalizados, tam. Medicina general. Enfermería P y P. Pediatría, Dermatología, Medicina Interna, Ginecología, Otorrinolaringología, Alergología. Medicina Familiar. Nutrición. Odontología general, Endodoncia y Ortodoncia. Terapia Física, Terapia Respiratoria, Terapia Ocupacional y Terapia de Lenguaje.",
        image: "/assets/ips/PROMOVER.png",
        web: "https://promoversas.com.co/",
      },
      {
        id: 13,
        title: "SAN ÁNGEL SANAR SAS",
        description:
          "Prestadora de Salud cuenta con una infraestructura en salud especializada en diversas ramas de la medicina para brindar la mejor comodidad, trato humanizado y excelencia en la atención por medio de profesionales idóneos y equipos especializados. Acompaña a los pacientes en la búsqueda del bienestar, salud y belleza.",
        image: "/assets/ips/OSTEOPLASTIC.png",
        web: "https://osteoplastic.co/",
      },
      {
        id: 14,
        title: "SHARON MEDICAL GROUP SAS",
        description:
          "Institución de servicios de salud de mediana y alta complejidad. Tiene como objeto social la prestación de servicios de salud. Cuenta con un equipo humano calificado, caracterizado por la integralidad de sus acciones, capacidad resolutiva, compromiso con la calidad, la humanización, el desarrollo científico y la seguridad, con la finalidad de brindar una atención integral a los usuarios y sus familias.",
        image: "/assets/ips/SHARON.png",
        web: "https://www.clinicasharonmedicalgroup.com/",
      },
      {
        id: 15,
        title: "TRAUMASUR TIENDA DE LA SALUD",
        description:
          "Empresa dedicada a la fabricación y comercialización de dispositivos médicos, la cual genera calidad de vida aportando soluciones inmediatas para nuestros clientes, con gran oportunidad y sello diferencial en materiales de alta calidad, asegurando el bienestar del talento humano y reforzando nuestra oportunidad de crecimiento y desarrollo.",
        image: "/assets/ips/TRAUMASUR.png",
        web: "http://traumasur.com/",
      },
      {
        id: 16,
        title: "UROCADIZ ESPECIALIDADES MEDICO QUIRÚRGICOS S.A.S.",
        description:
          "Institución dedicada al diagnóstico y tratamiento de un campo amplio de enfermedades. Se cuenta con diversas especialidades medico quirúrgicas. Con el excelente trabajo en equipo, se ofrece a los usuarios una atención integral, garantizándoles un seguimiento permanente, brindando una atención médica oportuna y programada con una constante vocación por la calidad, teniendo en cuenta no solo el aspecto médico sino la eficiencia y calidez en la atención.",
        image: "/assets/ips/UROCADIZ.png",
        web: "https://www.urocadiz.com/",
      },
    ],
    packages: [
      {
        id: "0",
        section_title:"Paquetes",
        see_more:"Ver más",
        title: "TRATAMIENTO DE REPRODUCCIÓN ASISTIDA",
        description:
          "El paquete del procedimiento de fertilidad, tiene como objetivo brindar un tratamiento de reproducción asistida, por medio de un estudio y diagnóstico a las parejas con problemas de fertilidad, nuestro trabajo se centra en darles la seguridad de tener recién nacidos vivos y sanos, y que el tratamiento no ponga en riesgo la vida de la madre.",
        image: "/assets/packages/reproduccion-asistida.svg",
        usd: "61.440",
        cop: "233.470.312",
        usa: "91.500",
        details: [
          {
            name: "Estudio y diagnóstico de la pareja con problemas de fertilidad",
            price: "2.857.000 COP",
          },
          {
            name: "Exámenes hormonales y análisis de sangre",
            price: "1.355.100 COP",
          },
          {
            name: "Diagnóstico genético preimplantatorio",
            price: "22.810.106 COP",
          },
          {
            name: "Tratamiento de reproducción asistida",
            price: "206.448.106 COP",
          },
        ],
      },
      {
        id: "1",
        title: "REHABILITACIÓN ONCOLÓGICA ORAL (Cirugía Maxilar mínima)",
        description:
          "El paquete de oncología, tiene el propósito de brindar un diagnóstico, tratamiento y rehabilitación oncológica oral, por medio de tratamientos de prótesis dental y maxilofacial (oculares, auriculares y nasales), ofreciendo un servicio de calidad a todos nuestros clientes.",
        image: "/assets/packages/rehabilitacion-oncologica.svg",
        usd: "3.166",
        cop: "12.030.000",
        usa: "12.000",
        details: [
          {
            name: "Diagnóstico, tratamiento y rehabilitación oncológica oral",
            price: "220.000 COP",
          },
          {
            name: "Diagnóstico y tratamiento de patologías estomatognáticas para paciente oncológicos",
            price: "250.000 COP",
          },
          {
            name: "Toma de muestras",
            price: "120.000 COP",
          },
          {
            name: "Exámenes prequirúrgicos",
            price: "150.000 COP",
          },
          {
            name: "Densitometría Ósea",
            price: "220.000 COP",
          },
          {
            name: "Transporte VIP (15)",
            price: "450.000 COP",
          },
          {
            name: "Endodoncia, Qx maxilar mínima",
            price: "4.000.000 COP",
          },
          {
            name: "Nutrición consulta y plan",
            price: "600000 COP",
          },
          {
            name: "Sesiones Rehab (8)",
            price: "800.000 COP",
          },
          {
            name: "Consulta oncológica",
            price: "220.000 COP",
          },
          {
            name: "Prótesis dental y maxilofacial (oculares, auriculares, nasales)",
            price: "5.000.000 COP",
          },
        ],
      },
      {
        id: "2",
        title: "CIRUGÍA BARIÁTRICA",
        description:
          "El paquete de cirugía bariátrica tiene como objetivo ofrecer el mejor servicio para que cada procedimiento en la pérdida de exceso de peso “baypass gástrico” sea integral y de calidad, con el beneficio de reducir el riesgo en los pacientes de tener problemas de salud.",
        image: "/assets/packages/cirugia-bariatrica.svg",
        usd: "4.778",
        cop: "18.156.100",
        usa: "25.500",
        details: [
          {
            name: "Laboratorio clínico - domicilio en el hotel",
            price: "1.636.100 COP",
          },
          {
            name: "Transporte VIP",
            price: "150.000 COP",
          },
          {
            name: "Domicilio",
            price: "50.000 COP",
          },
          {
            name: "Densitometría Ósea",
            price: "220.000 COP",
          },
          {
            name: "Bypass gástrico",
            price: "14.000.000 COP",
          },
          {
            name: "Nutrición consulta y plan",
            price: "900.000 COP",
          },
          {
            name: "Fajas y elementos postquirúrgicos",
            price: "600.000 COP",
          },
          {
            name: "Talleres de arte, lúdica y fortalecimiento personal",
            price: "200.000 COP",
          },
          {
            name: "Atención domiciliaria: Auxiliar de enfermería - manejo del dolor",
            price: "400.000 COP",
          },
        ],
      },
      {
        id: "3",
        title: "TRATAMIENTO DENTAL ESTÉTICO (DISEÑO)",
        description:
          "El paquete de dental estético le brinda a sus pacientes tratamientos en blanqueamiento, implantes dentales, diseño de sonrisa, armonización oro facial, endodoncia, periodoncia, ortodoncia, asegurando al cliente calidad en cada uno de nuestros servicios y que sientan seguridad con cada uno de los procedimientos que se realicen, satisfaciendo sus necesidades.",
        image: "/assets/packages/arte-oral-estetica-dental.svg",
        usd: "7.389",
        cop: "28.077.300",
        usa: "15.000",
        details: [
          {
            name: "Laboratorio clínico - domicilio en el hotel",
            price: "377.300 COP",
          },
          {
            name: "Insumos Implantes dentales, Regeneraciones, Insumos médicos quirúrgicos",
            price: "2.500.000 COP",
          },
          {
            name: "Diseño de sonrisa",
            price: "25.000.000 COP",
          },
          {
            name: "Talleres de arte, lúdica y fortalecimiento personal",
            price: "200.000 COP",
          },
        ],
      },
      {
        id: "4",
        title: "TRATAMIENTO DENTAL ESTÉTICO (BLANQUEAMIENTO)",
        description:
          "El paquete de dental estético le brinda a sus pacientes tratamientos en blanqueamiento, implantes dentales, diseño de sonrisa, armonización oro facial, endodoncia, periodoncia, ortodoncia, asegurando al cliente calidad en cada uno de nuestros servicios y que sientan seguridad con cada uno de los procedimientos que se realicen, satisfaciendo sus necesidades.",
        image: "/assets/packages/arte-oral-estetica-dental.svg",
        usd: "495",
        cop: "1.877.300",
        usa: "4.500",
        details: [
          {
            name: "Laboratorio clínico - domicilio en el hotel",
            price: "377.300 COP",
          },
          {
            name: "Blanqueamiento",
            price: "1.300.000 COP",
          },
          {
            name: "Talleres de arte, lúdica y fortalecimiento personal",
            price: "200.000 COP",
          },
        ],
      },
      {
        id: "5",
        title: "CIRUGÍA PLÁSTICA Y RECONSTRUCTIVA (MAMOPLASTIA)",
        description:
          "El paquete de cirugia plastica estetica, cuenta con los mejores especialistas que brindan el servicio de rejuvenecimiento vaginal, labioplastia, Mamoplastia, Abdominoplastia, Rinoplastia, Liposucción, blefaroplastia, botox, mini cervicoplastia y colágeno. Ofreciéndole al cliente la mejor atención en medicina integral y satisfacción de sus necesidades estéticas.",
        image: "/assets/packages/cirugia-plastica.svg",
        usd: "2.908",
        cop: "11.050.000",
        usa: "7.900",
        details: [
          {
            name: "Transporte VIP",
            price: "150.000 COP",
          },
          {
            name: "Exámenes prequirúrgicos",
            price: "150.000 COP",
          },
          {
            name: "Imágenes",
            price: "150.000 COP",
          },
          {
            name: "Mamoplastia",
            price: "8.800.000 COP",
          },
          {
            name: "Nutrición consulta y plan",
            price: "600.000 COP",
          },
          {
            name: "Atención domiciliaria: Auxiliar de enfermería - manejo del dolor",
            price: "400.000 COP",
          },
          {
            name: "Fajas y elementos postquirúrgicos",
            price: "600.000 COP",
          },
          {
            name: "Talleres de arte, lúdica y fortalecimiento personal",
            price: "200.000 COP",
          },
        ],
      },
      {
        id: "6",
        title: "CIRUGÍA PLÁSTICA Y RECONSTRUCTIVA (ABDOMINOPLASTIA)",
        description:
          "El paquete de cirugia plastica estetica, cuenta con los mejores especialistas que brindan el servicio de rejuvenecimiento vaginal, labioplastia, Mamoplastia, Abdominoplastia, Rinoplastia, Liposucción, blefaroplastia, botox, mini cervicoplastia y colágeno. Ofreciéndole al cliente la mejor atención en medicina integral y satisfacción de sus necesidades estéticas.",
        image: "/assets/packages/cirugia-plastica.svg",
        usd: "4.356",
        cop: "16.550.000",
        usa: "8.500",
        details: [
          {
            name: "Transporte VIP",
            price: "150.000 COP",
          },
          {
            name: "Exámenes prequirúrgicos",
            price: "150.000 COP",
          },
          {
            name: "Imágenes",
            price: "150.000 COP",
          },
          {
            name: "Abdominoplastia",
            price: "14.300.000 COP",
          },
          {
            name: "Nutrición consulta y plan",
            price: "600.000 COP",
          },
          {
            name: "Atención domiciliaria: Auxiliar de enfermería - manejo del dolor",
            price: "400.000 COP",
          },
          {
            name: "Fajas y elementos postquirúrgicos",
            price: "600.000 COP",
          },
          {
            name: "Talleres de arte, lúdica y fortalecimiento personal",
            price: "200.000 COP",
          },
        ],
      },
      {
        id: "7",
        title: "CIRUGÍA DE PRÓSTATA",
        description:
          "El paquete de urología le ofrece al paciente un servicio integral y de calidad, en sus consultas, y diagnósticos por medio de exámenes, procedimientos e intervenciones. Contamos con un equipo altamente capacitado y con experiencia en esta especialidad.",
        image: "/assets/packages/urologia.svg",
        usd: "3.106",
        cop: "11.799.300",
        usa: "40.000",
        details: [
          {
            name: "Consulta de Urología para diagnóstico de hiperplasia prostática ",
            price: "220.000 COP",
          },
          {
            name: "Antígeno prostático, Bun, Creatinina, PT, PTT, Parcial de orina, Urocultivo",
            price: "147.300 COP",
          },
          {
            name: "Cistoscopia transuretral",
            price: "600.000 COP",
          },
          {
            name: "Ecografía de próstata, biopsia de próstata",
            price: "829.000 COP",
          },
          {
            name: "Estudio anatomopatológico",
            price: "400.000 COP",
          },
          {
            name: "Consulta de valoración por anestesiologia",
            price: "150.000 COP",
          },
          {
            name: "Exámenes prequirúrgicos",
            price: "150.000 COP",
          },
          {
            name: "Electrocardiograma",
            price: "50.000 COP",
          },
          {
            name: "RX de tórax",
            price: "53.000 COP",
          },
          {
            name: "Cirugía de Próstata: Prostatectomía transuretral, Prostatectomía transvesical, Prostatectomía Radical",
            price: "9.000.000 COP",
          },
          {
            name: "Estudio anatomopatológico",
            price: "200.000 COP",
          },
        ],
      },
      {
        id: "8",
        title: "CIRUGÍA DE CÁLCULOS",
        description:
          "El paquete de urología le ofrece al paciente un servicio integral y de calidad, en sus consultas, y diagnósticos por medio de exámenes, procedimientos e intervenciones. Contamos con un equipo altamente capacitado y con experiencia en esta especialidad.",
        image: "/assets/packages/urologia.svg",

        usd: "3.371",
        cop: "12.809.500",
        usa: "30.000",
        details: [
          {
            name: "Consulta de Urología para control pos quirúrgico",
            price: "220.000 COP",
          },
          {
            name: "Consulta de Urología para diagnóstico de litiasis renal, litiasis uretral, litiasis en vejiga",
            price: "220.000 COP",
          },
          {
            name: "Urotac",
            price: "787.500 COP",
          },
          {
            name: "Consulta de valoración por anestesiología",
            price: "150.000 COP",
          },
          {
            name: "Exámenes prequirúrgicoso",
            price: "829.000 COP",
          },
          {
            name: "Electrocardiograma",
            price: "400.000 COP",
          },
          {
            name: "RX de tórax",
            price: "150.000 COP",
          },
          {
            name: "Cirugía de Cálculos: Nefrolitotomía percutánea, Ureterolitotomía con láser Holmim, Cistolitotomia con Lasser Holmium",
            price: "10.000.000 COP",
          },
          {
            name: "Consulta de Urología para control pos quirúrgico",
            price: "53.000 COP",
          },
          {
            name: "Análsis metabólico",
            price: "",
          },
          {
            name: "Estudio físico químico cuantitativo de cálculos renales",
            price: "",
          },
        ],
      },
      {
        id: "9",
        title: "CIRUGÍA DE HALLUX VALGUS (JUANETES)",
        description:
          "El paquete de ortopedia moderna, ofrece un servicio de calidad que se enfoca en la utilización de técnicas en la Cirugía de mínima invasión Hallux Valgus (Juanetes), para brindarle a nuestros pacientes el mejor tratamiento quirúrgico y una recuperación más rápida.",
        image: "/assets/packages/ortopedia.svg",
        usd: "1.748",
        cop: "6.640.000",
        usa: "5.600",
        details: [
          {
            name: "Exámenes prequirúrgicos",
            price: "150.000 COP",
          },
          {
            name: "Radiografía (2)",
            price: "100.000 COP",
          },
          {
            name: "Tomografía (2)",
            price: "500.000 COP",
          },
          {
            name: "Cirugía de Hallux Valgus (Juanetes)",
            price: "4.890.000 COP",
          },
          {
            name: "Talleres de arte, lúdica y fortalecimiento personal",
            price: "200.000 COP",
          },
          {
            name: "Dispositivos médicos, osteosíntesis",
            price: "600.000 COP",
          },
          {
            name: "Rehabilitación - Terapia Física",
            price: "200.000 COP",
          },
        ],
      },
      {
        id: "10",
        title: "EXAMEN MÉDICO EJECUTIVO",
        description:
          "El paquete de salud preventiva personalizada tiene como enfoque realizar prácticas médicas que estén especialmente diseñadas en prevenir y evitar enfermedades. Nuestro servicio es promover, proteger y mantener a nuestros pacientes sanos, para brindarles bienestar e integridad.",
        image: "/assets/packages/salud-preventiva.svg",
        usd: "790",
        cop: "3.000.000",
        usa: "1.500",
        details: [
          {
            name: "Consulta y Valoraciones",
            price: "",
          },
          {
            name: "Laboratorio clínico - domicilio en el hotel",
            price: "",
          },
          {
            name: "Electrocardiograma",
            price: "",
          },
          {
            name: "Examenes de Imágenes",
            price: "",
          },
          {
            name: "Endoscopias",
            price: "",
          },
          {
            name: "Hospitalización",
            price: "",
          },
        ],
      },
      {
        id: "11",
        title: "EXAMEN MÉDICO EJECUTIVO CARDIOVASCULAR",
        description:
          "El paquete de salud preventiva personalizada tiene como enfoque realizar prácticas médicas que estén especialmente diseñadas en prevenir y evitar enfermedades. Nuestro servicio es promover, proteger y mantener a nuestros pacientes sanos, para brindarles bienestar e integridad.",
        image: "/assets/packages/salud-preventiva.svg",
        usd: "492",
        cop: "1.867.100",
        usa: "2.000",
        details: [
          {
            name: "Electrocardiografía Dinámica en 24 horas (Holter)",
            price: "528.700 COP",
          },
          {
            name: "Monitoreo de Presión Arterial (MAPA)",
            price: "528.700 COP",
          },
          {
            name: "Ecocardiograma",
            price: "687.300 COP",
          },
          {
            name: "Electrocardiogramas",
            price: "54.700 COP",
          },
          {
            name: "Rehabilitación Cardiaca",
            price: "67.600 COP",
          },
        ],
      },
      {
        id: "12",
        title: "CIRUGIA PLASTICA Y ESTETICA (BLEFAROPLASTIA)",
        description:
          "El paquete de cirugia plastica estetica, cuenta con los mejores especialistas que brindan el servicio de rejuvenecimiento vaginal, labioplastia, Mamoplastia, Abdominoplastia, Rinoplastia, Liposucción, blefaroplastia, botox, mini cervicoplastia y colágeno. Ofreciéndole al cliente la mejor atención en medicina integral y satisfacción de sus necesidades estéticas.",
        image: "/assets/packages/cirugia-plastica.svg",
        usd: "2.908",
        cop: "11.050.000",
        usa: "7.900",
        details: [
          {
            name: "Consulta de valoración por cirujano plástico modalidad virtual o presencial",
            price: "150.000 COP",
          },
          {
            name: "Exámenes prequirúrgicos de laboratorio Cuadro hemático, tiempos de coagulación, prueba COVID Antígeno",
            price: "150.000 COP",
          },
          {
            name: "Anestesia Local",
            price: "150.000 COP",
          },
          {
            name: "Procedimiento quirúrgico - Blefaroplastia",
            price: "8.800.000 COP",
          },
          {
            name: "Control post quirúrgico virtual o presencial",
            price: "600.000 COP",
          },
          {
            name: "Póliza de Seguro Complicaciones Cirugía Estética",
            price: "400.000 COP",
          },
          {
            name: "Acompañamiento personalizado",
            price: "600.000 COP",
          },
          {
            name: "Transporte VIP: Servicio de transporte privado para traslados terrestres en vehículos de alta gama con conductor y aire acondicionado",
            price: "200.000 COP",
          },
          {
            name: "Talleres de arte, lúdica y fortalecimiento personal",
            price: "200.000 COP",
          },
        ],
      },
      {
        id: "13",
        title: "CIRUGIA PLASTICA Y ESTETICA (MINICERVICOPLASTIA)",
        description:
          "El paquete de cirugia plastica estetica, cuenta con los mejores especialistas que brindan el servicio de rejuvenecimiento vaginal, labioplastia, Mamoplastia, Abdominoplastia, Rinoplastia, Liposucción, blefaroplastia, botox, mini cervicoplastia y colágeno. Ofreciéndole al cliente la mejor atención en medicina integral y satisfacción de sus necesidades estéticas.",
        image: "/assets/packages/cirugia-plastica.svg",
        usd: "2.908",
        cop: "11.050.000",
        usa: "7.900",
        details: [
          {
            name: "Consulta de valoración por cirujano plástico modalidad virtual o presencial",
            price: "150.000 COP",
          },
          {
            name: "Exámenes prequirúrgicos de laboratorio Cuadro hemático, tiempos de coagulación, prueba COVID Antígeno",
            price: "150.000 COP",
          },
          {
            name: "Anestesia Local",
            price: "150.000 COP",
          },
          {
            name: "Procedimiento quirúrgico - Minicervicoplastia ",
            price: "8.800.000 COP",
          },
          {
            name: "Control post quirúrgico virtual o presencial",
            price: "600.000 COP",
          },
          {
            name: "Póliza de Seguro Complicaciones Cirugía Estética",
            price: "400.000 COP",
          },
          {
            name: "Acompañamiento personalizado",
            price: "600.000 COP",
          },
          {
            name: "Transporte VIP: Servicio de transporte privado para traslados terrestres en vehículos de alta gama con conductor y aire acondicionado",
            price: "200.000 COP",
          },
          {
            name: "Talleres de arte, lúdica y fortalecimiento personal",
            price: "200.000 COP",
          },
        ],
      },
      {
        id: "14",
        title: "CIRUGIA PLASTICA Y ESTETICA (BOTOX)",
        description:
          "El paquete de cirugia plastica estetica, cuenta con los mejores especialistas que brindan el servicio de rejuvenecimiento vaginal, labioplastia, Mamoplastia, Abdominoplastia, Rinoplastia, Liposucción, blefaroplastia, botox, mini cervicoplastia y colágeno. Ofreciéndole al cliente la mejor atención en medicina integral y satisfacción de sus necesidades estéticas.",
        image: "/assets/packages/cirugia-plastica.svg",
        usd: "2.908",
        cop: "11.050.000",
        usa: "7.900",
        details: [
          {
            name: "Consulta de valoración por cirujano plástico modalidad virtual o presencial",
            price: "150.000 COP",
          },
          {
            name: "Anestesia Local",
            price: "150.000 COP",
          },
          {
            name: "Botox",
            price: "150.000 COP",
          },
          {
            name: "Consulta de control post aplicación del bótox modalidad virtual o presencial",
            price: "600.000 COP",
          },
          {
            name: "Acompañamiento personalizado",
            price: "600.000 COP",
          },
          {
            name: "Transporte VIP: Servicio de transporte privado para traslados terrestres en vehículos de alta gama con conductor y aire acondicionado",
            price: "200.000 COP",
          },
          {
            name: "Talleres de arte, lúdica y fortalecimiento personal",
            price: "200.000 COP",
          },
        ],
      },
      {
        id: "15",
        title: "CIRUGIA PLASTICA Y ESTETICA (HILOS TENSORES DE COLAGENO)",
        description:
          "El paquete de cirugia plastica estetica, cuenta con los mejores especialistas que brindan el servicio de rejuvenecimiento vaginal, labioplastia, Mamoplastia, Abdominoplastia, Rinoplastia, Liposucción, blefaroplastia, botox, mini cervicoplastia y colágeno. Ofreciéndole al cliente la mejor atención en medicina integral y satisfacción de sus necesidades estéticas.",
        image: "/assets/packages/cirugia-plastica.svg",
        usd: "2.908",
        cop: "11.050.000",
        usa: "7.900",
        details: [
          {
            name: "Consulta de valoración por cirujano plástico modalidad virtual o presencial",
            price: "150.000 COP",
          },
          {
            name: "Anestesia Local",
            price: "150.000 COP",
          },
          {
            name: "Hilos tensores de colágeno",
            price: "150.000 COP",
          },
          {
            name: "Consulta de control post aplicación del bótox modalidad virtual o presencial",
            price: "600.000 COP",
          },
          {
            name: "Acompañamiento personalizado",
            price: "600.000 COP",
          },
          {
            name: "Transporte VIP: Servicio de transporte privado para traslados terrestres en vehículos de alta gama con conductor y aire acondicionado",
            price: "200.000 COP",
          },
          {
            name: "Talleres de arte, lúdica y fortalecimiento personal",
            price: "200.000 COP",
          },
        ],
      },
    ],
    countries: [
      {
        id: "0",
        section_title:"Red de Tolimenses",
        see_more:"Ver más",
        title: "CANADÁ",
        image: "/assets/flags/canada.png",
        delegates: [
          {
            image: "/assets/delegates/oscar_ortiz.jpg",
            name: "Oscar Ortiz",
            city: "Quevec",
            cel: "3242802435",
            email: "delegados@destinotolima.com",
          },
        ],
      },
      {
        id: "1",
        title: "COLOMBIA",
        image: "/assets/flags/colombia.png",
        delegates: [
          {
            image: "/assets/delegates/carlos_eduardo_barrios.jpg",
            name: "Carlos Eduardo Barrios",
            city: "Bogotá",
            cel: "3242802435",
            email: "delegados@destinotolima.com",
          },
        ],
      },
      {
        id: "2",
        title: "ESTADOS UNIDOS",
        image: "/assets/flags/estados_unidos.png",
        delegates: [
          {
            image: "/assets/delegates/maria_jameson.jpg",
            name: "Maria Jameson",
            city: "Dallas",
            cel: "3242802435",
            email: "delegados@destinotolima.com",
          },
          {
            image: "/assets/delegates/maria_paula_marquez.jpg",
            name: "Maria Paula Marquez",
            city: "Chicago",
            cel: "3242802435",
            email: "delegados@destinotolima.com",
          },
        ],
      },
      {
        id: "3",
        title: "FRANCIA",
        image: "/assets/flags/francia.png",
        delegates: [
          {
            image: "/assets/delegates/bibiana_franco.jpg",
            name: "Bibiana Franco",
            city: "París",
            cel: "3242802435",
            email: "delegados@destinotolima.com",
          },
        ],
      },
      {
        id: "4",
        title: "INGLATERRA",
        image: "/assets/flags/inglaterra.png",
        delegates: [
          {
            image: "/assets/delegates/natalie_bejarano.jpg",
            name: "Natalie Bejarano",
            city: "Liverpool",
            cel: "3242802435",
            email: "delegados@destinotolima.com",
          },
        ],
      },
    ],
    professionals: [
      {
        id: 0,
        section_title:"Profesionales de la Salud",
        name: "Dr. ALBERTO BONNET ARCINIEGAS",
        title: "Urología",
        description:
          "Urólogo con Certificación en Urología Reconstructiva. Miembro de la Sociedad Colombiana de Urología y de la  Confederación Americana de Urología (CAU)",
        image: "/assets/icono-doctor.png",
        ipsImage1: "/assets/ips/150/SHARON.png",
      },
      {
        id: 1,
        name: "Dra. ALICIA SANDINO NEIRA",
        title: "Cirugía plástica reconstructiva y estética",
        description:
          "Participó en el 2013 Resident´s Leadership Conference, en Norfolk (VA)",
        image: "/assets/professionals/urocadiz/alicia-sandino.jpeg",
        ipsImage1: "/assets/ips/150/UROCADIZ.png",
      },
      {
        id: 2,
        name: "Dra. ALMA PATRICIA RAMÍREZ CÓRDOBA",
        title: "Radiología",
        description:
          "Radióloga, con entrenamiento en Enfermedades de la Mama Hospital Gregorio Marañón, España. Recertificada por la Asociación Colombiana de Radiología 2010-2020. Certificada ACOMM en Densitometría Clínica 2016-2019, Experiencia de 26 años en Radiología General, Mama, TAC, Ecografía, Doppler, Densitometría Ósea",
        image: "/assets/professionals/heliscan/alma-patricia-ramirez.jpg",
        ipsImage1: "/assets/ips/150/HELISCAN.png",
        ipsImage2: "/assets/ips/150/OSTEOPOR.png",
      },
      {
        id: 3,
        name: "Dr. ÁLVARO ERNESTO LASSO ACOSTA",
        title: "Ortopedia y Traumatólogía",
        description:
          "Médico especialista en Ortopedia y Traumatología egresado del Instituto de Pós Graduación Médica Carlos Chagas – Universidad federal de Rio de Janeiro en el año 2012",
        image: "/assets/icono-doctor.png",
        ipsImage1: "/assets/ips/150/SHARON.png",
      },
      {
        id: 4,
        name: "ANDRES FELIPE VIVAS ANDRADE",
        title: "Diagnóstico por imágenes",
        description:
          'Medico Radiólogo; Curso de Radio Física Sanitaria - Sociedad Argentina de Radiología -2020.  Participacion en el Congreso Aregentino de diagnostico por Imagenes -agosto-2019 "hallazgos y utilidad del estudio dinámico del LCR en hidrocefalia" ',
        image: "/assets/professionals/heliscan/andres-felipe-vivas.jpeg",
        ipsImage1: "/assets/ips/150/HELISCAN.png",
      },
      {
        id: 5,
        name: "Dr. CESAR AUGUSTO ROJAS RODRIGUEZ",
        title: "Urología",
        description:
          "Urólogo, con mayor desempeño en áreas de manejo de litiasis de las vías urinarias por vía endoscópica, hiperplasia prostática e incontinencia urinaria femenina",
        image: "/assets/professionals/urotol/cesar-augusto.png",
        ipsImage1: "/assets/ips/150/UROTOL.png",
      },
      {
        id: 6,
        name: "Dra. CLARA JIMENA ALCAZAR MANRIQUE",
        title: "Cirugía Plástica, Estética y Reconstructiva",
        description:
          "Médica cirujana plástica, estética y reconstructiva con más de 8 años de experiencia en tratamiento quirúrgico; con estudios especializados en Cirugía Facial",
        image: "/assets/icono-doctora.png",
        ipsImage1: "/assets/ips/150/OSTEOPLASTIC.png",
      },
      {
        id: 7,
        name: "Dra. DAISY XIMENA ROA SAAVEDRA",
        title: "Urología",
        description:
          "Médico urólogo, con inclinación por la urología funcional y la endourología, miembro de número de la Sociedad Colombina de urología SCU y afiliación activa a la asociación americana de urología",
        image: "/assets/professionals/urotol/daisy-ximena.png",
        ipsImage1: "/assets/ips/150/UROTOL.png",
      },
      {
        id: 8,
        name: "Dr. DANIEL FERNANDO TORRES SALAZAR",
        title: "Urología",
        description:
          "Especialista en Urología y Endourología.  Miembro de la Sociedad Colombiana de Urología y 31 años de experiencia",
        image: "/assets/professionals/urocadiz/daniel-fernando-torres.jpeg",
        ipsImage1: "/assets/ips/150/UROCADIZ.png",
      },
      {
        id: 9,
        name: "DANIELA GONZÁLEZ GARZÓN",
        title: "Bacterióloga",
        description:
          "Experiencia en Biología Molecular, Coordinadora Laboratorio Clínico  con experiencia 13 años",
        image: "/assets/professionals/analicemos/daniela-gonzalez.jpeg",
        ipsImage1: "/assets/ips/150/ANALICEMOS.png",
      },
      {
        id: 10,
        name: "DIANA PATRICIA AGUDELO CELIS",
        title: "Instrumentación quirúrgica",
        description:
          "Instrumentadora quirúrgica, creativa con visión estratégica y comercialización de productos, insumos y dispositivos médicos",
        image: "/assets/icono-doctora.png",
        ipsImage1: "/assets/ips/150/TRAUMASUR.png",
      },
      {
        id: 11,
        name: "Dr. EDGARDO CABARCAS",
        title: "Ortopedia y traumatología",
        description:
          "Miembro de la Sociedad Colombiana de Ortopedia y Traumatología",
        image: "/assets/icono-doctor.png",
        ipsImage1: "/assets/ips/150/UROCADIZ.png",
      },
      {
        id: 12,
        name: "Dr. FRANCISCO DONALDO MELO AGUILAR",
        title: "Urología",
        description:
          "Especialista en Urología. Miembro de la Sociedad Colombiana de Urología con 15 años de experiencia",
        image: "/assets/icono-doctor.png",
        ipsImage1: "/assets/ips/150/UROCADIZ.png",
      },
      {
        id: 13,
        name: "Dr. ISRAEL RAMÍREZ",
        title: "Cirugía plástica",
        description:
          "Doctor en medicina y cirugía, Cirujano plástico de la universidad Javeriana de Bogotá con 38 años de experiencia. Miembro de la sociedad colombiana de cirugía plástica maxilofacial y de la mano",
        image: "/assets/icono-doctor.png",
        ipsImage1: "/assets/ips/150/UNICAT.png",
      },
      {
        id: 14,
        name: "Dr. JAVIER IGNACIO PARDO ARANGO",
        title: "Cirugía General",
        description:
          "Cirujano General y Cirugía Bariátrica, con Certificación en Soporte Nutricional de la Asociación Colombiana de Nutrición Clínica, con 22 años de experiencia",
        image: "/assets/icono-doctor.png",
        ipsImage1: "/assets/ips/150/SHARON.png",
      },
      {
        id: 15,
        name: "Dr. JHON JAIRO ORTIZ GARCIA",
        title: "Urología",
        description:
          "Especialista en Urología. Miembro de la Sociedad Colombiana de Urología con 16 años de experiencia",
        image: "/assets/icono-doctor.png",
        ipsImage1: "/assets/ips/150/UROCADIZ.png",
      },
      {
        id: 16,
        name: "Dr. JUAN CARLOS RODRIGUEZ LEONEL",
        title: "Ginecología y obstetricia",
        description:
          "Médico cirujano especialista en ginecología y obstetricia con 25 años de experiencia, cuenta con una sub – especialista en medicina materna fetal y sub – especialista en biomedicina reproductiva",
        image: "/assets/icono-doctor.png",
        ipsImage1: "/assets/ips/150/UNICAT.png",
      },
      {
        id: 17,
        name: "Dr. JUAN DAVID PARRA JIMENEZ",
        title: "Cardiología",
        description:
          "Medico Cardiólogo con 9 años de experiencia y entrenamiento en ecocardiografía de BAS y TEC de la Universidad de Ciencias Básicas de la Habana Cuba",
        image: "/assets/icono-doctor.png",
        ipsImage1: "/assets/ips/150/INSTITUTO-CARDIOVASCULAR-DEL-TOLIMA.png",
      },
      {
        id: 18,
        name: "Dr. JUAN FRANCISCO JAVIER OSPINA ORTIZ",
        title: "Ortopedia y Traumatología",
        description:
          "Ortopedista y traumatólogo, subespecialista en Cirugía en Pie y Tobillo con más de 7 años de experiencia en tratamientos quirúrgicos y rehabilitación",
        image: "/assets/icono-doctor.png",
        ipsImage1: "/assets/ips/150/OSTEOPLASTIC.png",
      },
      {
        id: 19,
        name: "Dra. LUCY RAMÍREZ LONDOÑO",
        title: "Odontología. Oncología oral y prótesis maxilofacial",
        description:
          "Odontóloga con 20 años de experiencia en la atención del paciente con cáncer y otras enfermedades sistémicas",
        image: "/assets/professionals/oncomedic/lucy-ramirez.jpeg",
        ipsImage1: "/assets/ips/150/ONCOMEDIC.png",
      },
      {
        id: 20,
        name: "Dr. LUIS ERNESTO OLIVEROS MÉNDEZ",
        title: "Cirugía plástica, reconstructiva y estética",
        description:
          "Médico cirujano plástico, reconstructivo y estético. Miembro de la sociedad colombiana de cirugía plástica estética y reconstructiva. Miembro de la asociación americana de cirujanos",
        image: "/assets/professionals/medicadiz/luis-ernesto-oliveros.png",
        ipsImage1: "/assets/ips/150/MEDICADIZ.png",
      },
      {
        id: 21,
        name: "Dr. LUIS FERNANDO ZAPATA MADRID",
        title: "Urología",
        description:
          "Médico Urólogo. Jefe del Servicio de Urología del Hospital Departamental Federico Lleras Acosta por 20 años, Secretario General de la Sociedad Colombiana de Urología por espacio de 4 años",
        image: "/assets/professionals/urotol/luis-zapata.png",
        ipsImage1: "/assets/ips/150/UROTOL.png",
      },
      {
        id: 22,
        name: "Dr. MARIO BONNET ARCINIEGAS",
        title: "Urología",
        description:
          "Médico Urólogo. Miembro de número de la Sociedad Colombiana de Urología y de la Sociedad Europea de Urología. Recertificado por la Sociedad Colombiana de Urología 2021-2023",
        image: "/assets/professionals/urotol/mario-bonnet.png",
        ipsImage1: "/assets/ips/150/UROTOL.png",
      },
      {
        id: 23,
        name: " Dr. MARIO DIDI CRUZ SÁNCHEZ",
        title: "Cirugía Plástica",
        description:
          "Cirujano Plástico con formación en Microcirugía. Miembro activo de la Sociedad Colombiana de Cirugía Plástica, Estética y Reconstructiva. Miembro Internacional de la Sociedad Americana de Cirujanos Plásticos",
        image: "/assets/icono-doctor.png",
        ipsImage1: "/assets/ips/150/SHARON.png",
      },
      {
        id: 24,
        name: "Dr. MAURICIO ANDRÉS LÓPEZ GUERRERO",
        title: "Urología",
        description:
          "Médico y cirujano,  con formación en urología general, trasplante renal, urología oncológica, andrología, urología funcional, cirugía endoscópica, laparoscópica y abierta",
        image: "/assets/professionals/urotol/mauricio-lopez.png",
        ipsImage1: "/assets/ips/150/UROTOL.png",
      },
      {
        id: 25,
        name: "Dr. MAURICIO HENAO SILVA",
        title: "Medicina Interna y Cardiología",
        description:
          "Cardiólogo. Especialista en Ecocardiografía de la Universidad del Rosario. Experiencia de 20 años",
        image: "/assets/icono-doctor.png",
        ipsImage1: "/assets/ips/150/INSTITUTO-CARDIOVASCULAR-DEL-TOLIMA.png",
      },
      {
        id: 26,
        name: "Dr. NICOLÁS PRADA GARAY",
        title: "Cirugía plástica",
        description:
          "Médico con 22 años de experiencia en la realización de cirugía plástica estética y reconstructiva",
        image: "/assets/professionals/clinica_tolima/nicolas-prada-garay.jpg",
        ipsImage1: "/assets/ips/150/CLINICA-TOLIMA.png",
      },
      {
        id: 27,
        name: "Dr. OSCAR MAURICIO POVEDA ORTIZ",
        title: "Cirugía General",
        description:
          "Cirujano General- Cirugía Laparoscópica Avanzada y Revisional. Cirugía Bariátrica. Con 9 años de experiencia",
        image: "/assets/professionals/clinica_tolima/oscar-mauricio-poveda.jpg",
        ipsImage1: "/assets/ips/150/MEDICADIZ.png",
        ipsImage2: "/assets/ips/150/CLINICA-TOLIMA.png",
      },
      {
        id: 28,
        name: "Dr. VICTOR JOSE GIL ALDANA",
        title: "Cardiología",
        description:
          "Cardiólogo. Con Subespecialización en Hemodinamia y Cardiología Intervencionista y Vascular Periférico. Director Médico Instituto Cardiovascular del Tolima",
        image: "/assets/icono-doctor.png",
        ipsImage1: "/assets/ips/150/INSTITUTO-CARDIOVASCULAR-DEL-TOLIMA.png",
      },
      {
        id: 29,
        name: "Dr. ZAMIR ENRIQUE SEGURA PARRA",
        title: "Ortopedia y Traumatología",
        description:
          "Médico especialista en Ortopedia y Traumatología egresado del Instituto de Pós Graduación Médica Carlos Chagas – Universidad federal de Rio de Janeiro en el año 2012",
        image: "/assets/icono-doctor.png",
        ipsImage1: "/assets/ips/150/SHARON.png",
      },
    ],
    testimonials: [
      {
        id: "0",
        section_title: "Testimonios",
        title: "Luz Henao Velásquez",
        description:
          "Familiar de paciente atendido en Medicadiz. Recomendaron el Tolima y se enamoraron de su amabilidad, instalaciones y familiaridad",
        image: "/assets/testimonials/luz-henao-velasquez.jpg",
        video: "https://www.youtube.com/embed/HSCf_e8bDCQ?rel=0",
      },
      {
        id: "1",
        title: "Valeria Ortegón",
        description:
          "Bogotana que decidió realizarse un procedimiento quirúrgico en Ibagué. La decisión se basó en calidad, en atención y en precio",
        image: "/assets/testimonials/valeria_ortegon.jpg",
        video: "https://www.youtube.com/embed/aHMnvPzmI_I?rel=0",
      },
      {
        id: "2",
        title: "Mercy Collazos",
        description:
          "Experiencia y resultados del Doctor Israel Ramírez. Destino Tolima Salud",
        image: "/assets/testimonials/doctor_ismael_ramirez.jpg",
        video: "https://www.youtube.com/embed/0H3Wu5FYLcQ?rel=0",
      },
    ],
    sponsors: [
      {
        id: 0,
        section_title:"Aliados / Patrocinadores",
        image: "/assets/sponsors/mincomercio.png",
      },
      {
        id: 1,
        image: "/assets/sponsors/innpulsa.png",
      },
      {
        id: 2,
        image: "/assets/sponsors/cci.png",
      },
      {
        id: 3,
        image: "/assets/sponsors/cluster.png",
      },
      {
        id: 4,
        image: "/assets/sponsors/gobernacion-del-tolima.png",
      },
      {
        id: 5,
        image: "/assets/sponsors/alcaldia-de-ibague.png",
      },
    ],
    menus:{
      start:'Inicio',
      ips:'Ips',
      professionals:'Profesionales',
      packages: 'Paquetes',
      testimonials: 'Testimonios',
      form: 'Formulario'
    },
    banners:[
      {title1:'El Clima ', title2:'perfecto',description1:'Ambiental, cultural, y social para intervenciones médicas, ', description2:'recupérate y disfruta de una aventura acompañada de deporte',btn:'MÁS INFORMACIÓN'},
      {copy1:'Nuestros especialistas ',copy2:'hacen el ',copy3:'mejor ',copy4:'procedimiento, ',copy5:'tu familia ',copy6:'hace el ',copy7:'mejor ',copy8:'acompañamiento.',btn:'MÁS INFORMACIÓN'},
      {copy1:'Consulta los beneficios que tienes al usar nuestros servicios en uno de los mejores climas del país.',btn:'FORMULARIO'},
      {copy1:'Una ciudad en la que', copy2:'siempre hay un plan.', copy3:'Salud, recuperación, aventura, recreación, deporte y negocios.',btn:'DÉJATE SORPRENDER'},
    ],
    form:{
      copy1:'El primer paso para regresar al Tolima y hacerte el procedimiento médico que requieres al lado de tú familia y de la mano de un especialista amigo',
      copy2:'Para más información déjanos tus datos en el formulario',
      holder1:'Nombres',
      holder2:'Apellidos',
      holder3:'Correo Electronico',
      holder4:'WhatsApp',
      holder5:'Proceso, procedimiento o tratamiento de interés',
      btn:'Send',
    }
  },

  En: {
    ips: [
      {
        id: 0,
        section_title: "Service Provider Institutions",
        title: "ANALICEMOS LABORATORIO CLÍNICO ESPECIALIZADO",
        description:
          "Health provider institution with highly qualified personnel in the assistance area. With more than 12 years of experience in the processing of samples of biological origin in state-of-the-art equipment. Its professionalism and constant updating in new laboratory techniques characterize it.",
        image: "/assets/ips/ANALICEMOS.png",
        web: "https://analicemoslaboratorio.com/",
        professionals: [
          {
            id: 0,
            name: "Dr. ALBERTO BONNET ARCINIEGAS",
            title: "Urología",
            description:
              "Urólogo con Certificación en Urología Reconstructiva. Miembro de la Sociedad Colombiana de Urología y de la  Confederación Americana de Urología (CAU)",
            image: "/assets/icono-doctor.png",
          },
          {
            id: 1,
            name: "Dr. ALBERTO BONNET ARCINIEGAS",
            title: "Urología",
            description:
              "Urólogo con Certificación en Urología Reconstructiva. Miembro de la Sociedad Colombiana de Urología y de la  Confederación Americana de Urología (CAU)",
            image: "/assets/icono-doctor.png",
          },
          {
            id: 2,
            name: "Dr. ALBERTO BONNET ARCINIEGAS",
            title: "Urología",
            description:
              "Urólogo con Certificación en Urología Reconstructiva. Miembro de la Sociedad Colombiana de Urología y de la  Confederación Americana de Urología (CAU)",
            image: "/assets/icono-doctor.png",
          },
        ],
      },
      {
        id: 1,
        title: "CLÍNICA TOLIMA",
        description:
          "Clínica Tolima S.A is a traditional institution in the department of Tolima, which provides health services of medium and high complexity, with deep respect for human beings and their environment. Based on corporate principles, such as safety and humanization of the service, ensuring that bariatric and aesthetic surgery care meets the expectations of those who entrust their health to our services.",
        image: "/assets/ips/CLINICA-TOLIMA.png",
        web: "https://clinicatolima.com/",
      },
      {
        id: 2,
        title: "CLÍNICA UNICAT",
        description:
          "The UNIDAD DE CIRUGÍA DEL TOLIMA S.A  It provides friendly, timely, agile and efficient surgical and hospital health services framed in safe care, with scientific development, with a trained, committed human team; with ethics and as a fundamental principle provides loyalty and confidentiality to people.",
        image: "/assets/ips/UNICAT.png",
        web: "https://clinicaunicat.com/",
      },
      {
        id: 3,
        title2:
          "COOPERATIVA ESPECIALIZADA DE SERVICIOS UROLÓGICOS DEL TOLIMA - UROTOL",
        title: "UROTOL",
        description:
          "Institution provider of health services, specialized in Urology, has services in diagnosis, medical treatment, surgery and specialized examinations in the city of Ibagué, contributes to the improvement of the quality of life of its patients, with humanized care, providing services of excellent quality, constantly updating and renewing technology and knowledge of all the human talent of the organization.",
        image: "/assets/ips/UROTOL.png",
        web: "https://urotol.co/",
      },
      {
        id: 4,
        title: "DENTO-MAN S.A.S",
        description:
          "Health provider institution focused on providing a comprehensive and specialized dental service through a highly trained work team. With the implementation of appropriate technology, and, in a unique and relaxing environment, you will achieve the highest quality and biosafety standards in each of the dental procedures, making each consultation a pleasant, rewarding and positive experience.",
        image: "/assets/ips/DENTOMAN.png",
        web: "https://dentoman.wixsite.com/dentoman",
      },
      {
        id: 5,
        title: "HELISCAN",
        description:
          "Company provider of medical services in diagnostic images with 28 years of experience, created to offer, support and meet the needs of the community, medical professionals and any entity related to the provision of health services. The main services are: Mammography, ultrasound, tomography and x-rays.",
        image: "/assets/ips/HELISCAN.png",
        web: "https://detectarips.com/",
      },
      {
        id: 6,
        title: "INSTITUTO CARDIOVASCULAR DEL TOLIMA",
        description:
          "Created for the comprehensive management of cardiology services, highlighting the comprehensiveness in the provision of the service. High-tech supplies, devices and equipment. It offers services of: Cardiac rehabilitation therapies, Pacemaker Reprogramming, Cardio resynchronizer and Cardio defibrillator, Tilt Table Test, Systemic Blood Pressure Monitoring, Electrophysiology, Rhythm or Surface Electrocardiogram, Dynamic Electrocardiography, Trans esophageal Echocardiogram, Neuroradiology Consultation, among others.",
        image: "/assets/ips/INSTITUTO-CARDIOVASCULAR-DEL-TOLIMA.png",
        web: "https://inscardiovasculartolima.com/",
      },
      {
        id: 7,
        title: "IPS INTEGRAL SOMOS SALUD SAS",
        description:
          "Institution aimed at contributing to the improvement of the quality of life of users, which has suitable and committed professionals, who work continuously with efficiency, responsibility and human warmth, services are provided with high-tech equipment and an infrastructure that meets quality standards. Its main services are: Physiotherapy, respiratory therapy, occupational therapy, language therapy and/or speech therapy, nutrition, psychology, audiology, spirometry.",
        image: "/assets/ips/SOMOS-SALUD.png",
        web: "https://somossalud.co/",
      },
      {
        id: 8,
        title: "MEDICADIZ",
        description:
          "Private institution that offers highly complex health services, providing humanized care, committed to the continuous improvement of quality and patient safety, contributing to the well-being of patients, their families, collaborators, investors, and the community.",
        image: "/assets/ips/MEDICADIZ.png",
        web: "https://medicadiz.co/",
      },
      {
        id: 9,
        title: "ONCOMEDIC LTDA",
        description:
          "Private IPS created since 1994 for the care of cancer patients. It provides services with humane, safe, timely, efficient and effective treatment through the exercise of trained personnel articulated with innovation processes. The quality of life and prevention is a fundamental difference in the organization, respect for the individual and their processes help in the reconstruction of life.",
        image: "/assets/ips/ONCOMEDIC.png",
        web: "https://oncomedic.co/",
      },
      {
        id: 10,
        title: "OPTICAS ORSOVISION S.A.S",
        description:
          "Institution that provides visual health services, with the highest quality standards, the highest scientific technology and qualified professionals for the benefit of our users and the excellent image of allied entities. With 36 years of experience. Its main services are: Optometry Consultation, Orthoptics and Pleoptics, Visual Screenings, Contact Lens Fitting, Prosthesis Manufacturing and Fitting, Occupational Visiometry in different companies.",
        image: "/assets/ips/ORSOVISION.png",
        web: "https://www.orsovisionopticas.com/",
      },
      {
        id: 11,
        title: "OSTEOPOR",
        description:
          "Company provider of medical services related to diagnostic images and diagnostic aid tests with 25 years of experience. The greatest interest is the diagnostic support of diseases of adults and the elderly. Currently leaders in the diagnosis of Osteoporosis. Its main services are: bone densitometry, morphometry, body composition, shock wave therapy.",
        image: "/assets/ips/OSTEOPOR.png",
        web: "https://osteoporltda.com/",
      },
      {
        id: 12,
        title: "PROMOVER SAS",
        description:
          "Institution that provides health services with a 23-year tradition in the country. Among the services provided in the different locations are ambulances through Basic Assistance Transport, Tab and Medicalized, tam. General medicine. Nursing P and P. Pediatrics, Dermatology, Internal Medicine, Gynecology, Otolaryngology, Allergology. Family Medicine. Nutrition. General Dentistry, Endodontics and Orthodontics. Physical Therapy, Respiratory Therapy, Occupational Therapy and Speech Therapy.",
        image: "/assets/ips/PROMOVER.png",
        web: "https://promoversas.com.co/",
      },
      {
        id: 13,
        title: "SAN ÁNGEL SANAR SAS",
        description:
          "Health provider company has a health infrastructure specialized in various branches of medicine to provide the best comfort, humane treatment and excellence in care through suitable professionals and specialized teams. Accompany patients in the search for well-being, health and beauty.",
        image: "/assets/ips/OSTEOPLASTIC.png",
        web: "https://osteoplastic.co/",
      },
      {
        id: 14,
        title: "SHARON MEDICAL GROUP SAS",
        description:
          "Institution of medium and high complexity health services. Its corporate purpose is the provision of health services. It has a qualified human team, characterized by the integrity of its actions, problem-solving capacity, commitment to quality, humanization, scientific development and safety, in order to provide comprehensive care to users and their families.",
        image: "/assets/ips/SHARON.png",
        web: "https://www.clinicasharonmedicalgroup.com/",
      },
      {
        id: 15,
        title: "TRAUMASUR TIENDA DE LA SALUD",
        description:
          "Company dedicated to the manufacture and marketing of medical devices, which generates quality of life by providing immediate solutions for our clients, with great opportunities and a differential seal in high-quality materials, ensuring the well-being of human talent and reinforcing our opportunity for growth and development.",
        image: "/assets/ips/TRAUMASUR.png",
        web: "http://traumasur.com/",
      },
      {
        id: 16,
        title: "UROCADIZ ESPECIALIDADES MEDICO QUIRÚRGICOS S.A.S.",
        description:
          "Institution dedicated to the diagnosis and treatment of a wide field of diseases. There are various medical and surgical specialties. With excellent teamwork, users are offered comprehensive care, guaranteeing permanent follow-up, providing timely and scheduled medical care with a constant vocation for quality, taking into account not only the medical aspect but also efficiency and warmth in the attention.",
        image: "/assets/ips/UROCADIZ.png",
        web: "https://www.urocadiz.com/",
      },
    ],
    packages: [
      {
        id: "0",
        section_title:"Packages",
        see_more:"View more",
        title: "ASSISTED REPRODUCTION TREATMENT",
        description:
          "The fertility procedure package’s aim is to provide assisted reproduction treatment, through a study and diagnosis to couples with fertility problems, our work focuses on giving them the security of having live and healthy newborns, and that the treatment does not put the life of the mother at risk.",
        image: "/assets/packages/reproduccion-asistida.svg",
        usd: "61.440",
        cop: "233.470.312",
        usa: "91.500",
        details: [
          {
            name: "Study and diagnosis of the couple with fertility problems",
            price: "2.857.000 COP",
          },
          {
            name: "Hormone tests and blood tests",
            price: "1.355.100 COP",
          },
          {
            name: "Preimplantation genetic diagnosis",
            price: "22.810.106 COP",
          },
          {
            name: "Assisted reproductive treatment",
            price: "206.448.106 COP",
          },
        ],
      },
      {
        id: "1",
        title: "ORAL ONCOLOGICAL REHABILITATION (Minimal Maxillary Surgery)",
        description:
          "The oncology package has the purpose of providing diagnosis, treatment and oral oncology rehabilitation, through dental and maxillofacial prosthesis treatments (ocular, auricular and nasal), offering a quality service to all our clients.",
        image: "/assets/packages/rehabilitacion-oncologica.svg",
        usd: "3.166",
        cop: "12.030.000",
        usa: "12.000",
        details: [
          {
            name: "Diagnosis, treatment and oral oncology rehabilitation",
            price: "220.000 COP",
          },
          {
            name: "Diagnosis and treatment of stomatognathic pathologies for cancer patients",
            price: "250.000 COP",
          },
          {
            name: "Sampling",
            price: "120.000 COP",
          },
          {
            name: "Pre-surgical exams",
            price: "150.000 COP",
          },
          {
            name: "Bone densitometry",
            price: "220.000 COP",
          },
          {
            name: "VIP Transport (15)",
            price: "450.000 COP",
          },
          {
            name: "Endodontics, Qx minimal maxilla",
            price: "4.000.000 COP",
          },
          {
            name: "Nutrition consultation and plan",
            price: "600000 COP",
          },
          {
            name: "Rehabilitation sessions (8)",
            price: "800.000 COP",
          },
          {
            name: "Oncology consultation",
            price: "220.000 COP",
          },
          {
            name: "Dental and maxillofacial prostheses (Ocular, auricular, nasal)",
            price: "5.000.000 COP",
          },
        ],
      },
      {
        id: "2",
        title: "BARIATRIC SURGERY",
        description:
          'The bariatric surgery package aims to offer the best service so that each procedure in the loss of excess weight "gastric bypass" is comprehensive and of quality, with the benefit of reducing the risk in patients of having health problems.',
        image: "/assets/packages/cirugia-bariatrica.svg",
        usd: "4.778",
        cop: "18.156.100",
        usa: "25.500",
        details: [
          {
            name: "Clinical laboratory - address in the hotel",
            price: "1.636.100 COP",
          },
          {
            name: "VIP transportation",
            price: "150.000 COP",
          },
          {
            name: "Home",
            price: "50.000 COP",
          },
          {
            name: "Bone densitometry",
            price: "220.000 COP",
          },
          {
            name: "Gastric bypass",
            price: "14.000.000 COP",
          },
          {
            name: "Nutrition consultation and plan",
            price: "900.000 COP",
          },
          {
            name: "Post-surgical girdles and elements",
            price: "600.000 COP",
          },
          {
            name: "Art, playful and personal strengthening workshops",
            price: "200.000 COP",
          },
          {
            name: "Home care: Nursing assistant - pain management",
            price: "400.000 COP",
          },
        ],
      },
      {
        id: "3",
        title: "AESTHETIC DENTAL TREATMENT (DESIGN)",
        description:
          "The aesthetic dental package provides its patients with whitening treatments, dental implants, smile design, gold facial harmonization, endodontics, periodontics, orthodontics, assuring the client quality in each of our services in a way that they feel safe with each of the procedures that are carried out, satisfying your needs.",
        image: "/assets/packages/arte-oral-estetica-dental.svg",
        usd: "7.389",
        cop: "28.077.300",
        usa: "15.000",
        details: [
          {
            name: "Clinical laboratory - address in the hotel",
            price: "377.300 COP",
          },
          {
            name: "Supplies Dental implants, Regenerations, Surgical medical supplies",
            price: "2.500.000 COP",
          },
          {
            name: "Smile design",
            price: "25.000.000 COP",
          },
          {
            name: "Art, playful and personal strengthening workshops",
            price: "200.000 COP",
          },
        ],
      },
      {
        id: "4",
        title: "AESTHETIC DENTAL TREATMENT (WHITENING)",
        description:
          "The aesthetic dental package provides its patients with whitening treatments, dental implants, smile design, gold facial harmonization, endodontics, periodontics, orthodontics, assuring the client quality in each of our services in a way that they feel safe with each of the procedures, which are carried out, satisfying our patient's needs.",
        image: "/assets/packages/arte-oral-estetica-dental.svg",
        usd: "495",
        cop: "1.877.300",
        usa: "4.500",
        details: [
          {
            name: "Clinical laboratory - address in the hotel",
            price: "377.300 COP",
          },
          {
            name: "Whitening",
            price: "1.300.000 COP",
          },
          {
            name: "Art, play and personal strengthening workshops",
            price: "200.000 COP",
          },
        ],
      },
      {
        id: "5",
        title: "PLASTIC AND RECONSTRUCTIVE SURGERY (MAMMOPLASTY)",
        description:
          "The aesthetic plastic surgery package has the best specialists who provide the service of vaginal rejuvenation, labiaplasty, mammoplasty, tummy tuck, rhinoplasty, liposuction, blepharoplasty, botox, mini cervicoplasty and collagen. Offering the client the best care in integral medicine and satisfaction of their aesthetic needs.",
        image: "/assets/packages/cirugia-plastica.svg",
        usd: "2.908",
        cop: "11.050.000",
        usa: "7.900",
        details: [
          {
            name: "VIP transportation",
            price: "150.000 COP",
          },
          {
            name: "Pre-surgical exams",
            price: "150.000 COP",
          },
          {
            name: "Images",
            price: "150.000 COP",
          },
          {
            name: "Mammoplasty",
            price: "8.800.000 COP",
          },
          {
            name: "Nutrition consultation and plan",
            price: "600.000 COP",
          },
          {
            name: "Home care: Nursing assistant - pain management",
            price: "400.000 COP",
          },
          {
            name: "Post-surgical girdles and elements",
            price: "600.000 COP",
          },
          {
            name: "Art workshops, playful and personal strengthening",
            price: "200.000 COP",
          },
        ],
      },
      {
        id: "6",
        title: "PLASTIC AND RECONSTRUCTIVE SURGERY (ABDOMINOPLASTY)",
        description:
          "The cosmetic plastic surgery package has the best specialists who provide the service of vaginal rejuvenation, labiaplasty, mammoplasty, tummy tuck, rhinoplasty, liposuction, blepharoplasty, botox, mini cervicoplasty and collagen. Offering the client the best care in integral medicine and satisfaction of their aesthetic needs.",
        image: "/assets/packages/cirugia-plastica.svg",
        usd: "4.356",
        cop: "16.550.000",
        usa: "8.500",
        details: [
          {
            name: "VIP transportation",
            price: "150.000 COP",
          },
          {
            name: "Pre-surgical exams",
            price: "150.000 COP",
          },
          {
            name: "Images",
            price: "150.000 COP",
          },
          {
            name: "Abdominoplasty",
            price: "14.300.000 COP",
          },
          {
            name: "Nutrition consultation and plan",
            price: "600.000 COP",
          },
          {
            name: "Home care: Nursing assistant - pain management",
            price: "400.000 COP",
          },
          {
            name: "Post-surgical girdles and elements",
            price: "600.000 COP",
          },
          {
            name: "Art, playful and personal strengthening workshops",
            price: "200.000 COP",
          },
        ],
      },
      {
        id: "7",
        title: "PROSTATE SURGERY",
        description:
          "The urology package offers the patient a comprehensive and quality service, in their consultations, and diagnoses through examinations, procedures and interventions. We have a highly trained team with experience in this specialty.",
        image: "/assets/packages/urologia.svg",
        usd: "3.106",
        cop: "11.799.300",
        usa: "40.000",
        details: [
          {
            name: "Urology consultation for diagnosis of prostatic hyperplasia",
            price: "220.000 COP",
          },
          {
            name: "Prostate antigen, Bun, Creatinine, PT, PTT, Partial urine, Urine culture",
            price: "147.300 COP",
          },
          {
            name: "Transurethral cystoscopy",
            price: "600.000 COP",
          },
          {
            name: "Prostate ultrasound, prostate biopsy",
            price: "829.000 COP",
          },
          {
            name: "Pathological study",
            price: "400.000 COP",
          },
          {
            name: "Assessment consultation by anesthesiology",
            price: "150.000 COP",
          },
          {
            name: "Pre-surgical exams",
            price: "150.000 COP",
          },
          {
            name: "Electrocardiogram",
            price: "50.000 COP",
          },
          {
            name: "Chest X-ray",
            price: "53.000 COP",
          },
          {
            name: "Prostate Surgery: Transurethral Prostatectomy, Transvesical Prostatectomy, Radical Prostatectomy",
            price: "9.000.000 COP",
          },
          {
            name: "Pathological study",
            price: "200.000 COP",
          },
        ],
      },
      {
        id: "8",
        title: "Kidney Stone surgery",
        description:
          "The urology package offers the patient a comprehensive and quality service, in their consultations, and diagnoses through examinations, procedures and interventions. We have a highly trained team with experience in this specialty.",
        image: "/assets/packages/urologia.svg",
        usd: "3.371",
        cop: "12.809.500",
        usa: "30.000",
        details: [
          {
            name: "Urology consultation for post-surgical control",
            price: "220.000 COP",
          },
          {
            name: "Urology consultation for diagnosis of renal lithiasis, urethral lithiasis, bladder lithiasis",
            price: "220.000 COP",
          },
          {
            name: "Urotac",
            price: "787.500 COP",
          },
          {
            name: "Assessment consultation by anesthesiology",
            price: "150.000 COP",
          },
          {
            name: "Pre-surgical exams",
            price: "829.000 COP",
          },
          {
            name: "Electrocardiogram",
            price: "400.000 COP",
          },
          {
            name: "chest X-ray",
            price: "150.000 COP",
          },
          {
            name: "Stone Surgery: Percutaneous nephrolithotomy, Ureterolithotomy with Holmim laser, Cystolithotomy with Lasser Holmium",
            price: "10.000.000 COP",
          },
          {
            name: "Urology consultation for post-surgical control",
            price: "53.000 COP",
          },
          {
            name: "Metabolic analysis",
            price: "",
          },
          {
            name: "Quantitative physical chemical study of kidney stones",
            price: "",
          },
        ],
      },
      {
        id: "9",
        title: "HALLUX VALGUS SURGERY (BUNIONS)",
        description:
          "The modern orthopedics package offers a quality service, which focuses on the use of techniques in Minimally Invasive Hallux Valgus Surgery (Bunions), to provide our patients with the best surgical treatment and a faster recovery.",
        image: "/assets/packages/ortopedia.svg",
        usd: "1.748",
        cop: "6.640.000",
        usa: "5.600",
        details: [
          {
            name: "Pre-surgical exams",
            price: "150.000 COP",
          },
          {
            name: "X-ray (2)",
            price: "100.000 COP",
          },
          {
            name: "Tomography (2)",
            price: "500.000 COP",
          },
          {
            name: "Hallux Valgus Surgery (Bunions)",
            price: "4.890.000 COP",
          },
          {
            name: "Art, play and personal strengthening workshops",
            price: "200.000 COP",
          },
          {
            name: "Medical devices, osteosynthesis",
            price: "600.000 COP",
          },
          {
            name: "Rehabilitation - Physical Therapy",
            price: "200.000 COP",
          },
        ],
      },
      {
        id: "10",
        title: "EXECUTIVE MEDICAL EXAMINATION",
        description:
          "The personalized preventive health package focuses on carrying out medical practices that are specially designed to prevent and avoid diseases. Our service is to promote, protect and keep our patients healthy, to provide them with well-being and integrity.",
        image: "/assets/packages/salud-preventiva.svg",
        usd: "790",
        cop: "3.000.000",
        usa: "1.500",
        details: [
          {
            name: "Consultation and Evaluations",
            price: "",
          },
          {
            name: "Clinical laboratory - address in the hotel",
            price: "",
          },
          {
            name: "Electrocardiogram",
            price: "",
          },
          {
            name: "Imaging Exams",
            price: "",
          },
          {
            name: "Endoscopies",
            price: "",
          },
          {
            name: "Hospitalization",
            price: "",
          },
        ],
      },
      {
        id: "11",
        title: "EXECUTIVE CARDIOVASCULAR MEDICAL EXAMINATION",
        description:
          "The personalized preventive health package focuses on carrying out medical practices that are specially designed to prevent and avoid diseases. Our service is to promote, protect and keep our patients healthy, to provide them with well-being and integrity.",
        image: "/assets/packages/salud-preventiva.svg",
        usd: "492",
        cop: "1.867.100",
        usa: "2.000",
        details: [
          {
            name: "Dynamic electrocardiography in 24 hours (Holter)",
            price: "528.700 COP",
          },
          {
            name: "Blood Pressure Monitoring (ABPM)",
            price: "528.700 COP",
          },
          {
            name: "Echocardiogram",
            price: "687.300 COP",
          },
          {
            name: "Electrocardiograms",
            price: "54.700 COP",
          },
          {
            name: "Cardiac Rehabilitation",
            price: "67.600 COP",
          },
        ],
      },
      {
        id: "12",
        title: "PLASTIC AND AESTHETIC SURGERY (BLEPHAROPLASTY)",
        description:
          "The cosmetic plastic surgery package has the best specialists who provide the service of vaginal rejuvenation, labiaplasty, mammoplasty, tummy tuck, rhinoplasty, liposuction, blepharoplasty, botox, mini cervicoplasty and collagen. Offering to the client the best care in integral medicine and satisfaction of their aesthetic needs.",
        image: "/assets/packages/cirugia-plastica.svg",
        usd: "2.908",
        cop: "11.050.000",
        usa: "7.900",
        details: [
          {
            name: "Valuation consultation by plastic surgeon virtual or face-to-face modality",
            price: "150.000 COP",
          },
          {
            name: "Pre-surgical laboratory tests Complete blood count, coagulation times, COVID Antigen test",
            price: "150.000 COP",
          },
          {
            name: "Local anesthesia",
            price: "150.000 COP",
          },
          {
            name: "Surgical Procedure - Blepharoplasty",
            price: "8.800.000 COP",
          },
          {
            name: "Virtual or face-to-face post-surgical control",
            price: "600.000 COP",
          },
          {
            name: "Cosmetic Surgery Complications Insurance Policy",
            price: "400.000 COP",
          },
          {
            name: "Personalized accompaniment",
            price: "600.000 COP",
          },
          {
            name: "VIP Transportation: Private transportation service for land transfers in high-end vehicles with driver and air conditioning",
            price: "200.000 COP",
          },
          {
            name: "Art, playful and personal strengthening workshops",
            price: "200.000 COP",
          },
        ],
      },
      {
        id: "13",
        title: "PLASTIC AND AESTHETIC SURGERY (MINI CERVICOPLASTY)",
        description:
          "The aesthetic plastic surgery package has the best specialists who provide the service of vaginal rejuvenation, labiaplasty, mammoplasty, tummy tuck, rhinoplasty, liposuction, blepharoplasty, botox, mini cervicoplasty and collagen. Offering the client the best care in integral medicine and satisfaction of their aesthetic needs.",
        image: "/assets/packages/cirugia-plastica.svg",
        usd: "2.908",
        cop: "11.050.000",
        usa: "7.900",
        details: [
          {
            name: "Valuation consultation by plastic surgeon virtual or face-to-face modality",
            price: "150.000 COP",
          },
          {
            name: "Pre-surgical laboratory tests Complete blood count, coagulation times, COVID Antigen test",
            price: "150.000 COP",
          },
          {
            name: "Local anesthesia",
            price: "150.000 COP",
          },
          {
            name: "Surgical Procedure -  Minicervicoplasty",
            price: "8.800.000 COP",
          },
          {
            name: "Virtual or face-to-face post-surgical control",
            price: "600.000 COP",
          },
          {
            name: "Cosmetic Surgery Complications Insurance Policy",
            price: "400.000 COP",
          },
          {
            name: "Personalized accompaniment",
            price: "600.000 COP",
          },
          {
            name: "VIP Transportation: Private transportation service for land transfers in high-end vehicles with driver and air conditioning",
            price: "200.000 COP",
          },
          {
            name: "Art, play and personal strengthening workshops",
            price: "200.000 COP",
          },
        ],
      },
      {
        id: "14",
        title: "PLASTIC AND AESTHETIC SURGERY (BOTOX)",
        description:
          "The aesthetic plastic surgery package has the best specialists who provide the service of vaginal rejuvenation, labiaplasty, mammoplasty, tummy tuck, rhinoplasty, liposuction, blepharoplasty, botox, mini cervicoplasty and collagen. Offering the client the best care in integral medicine and satisfaction of their aesthetic needs.",
        image: "/assets/packages/cirugia-plastica.svg",
        usd: "2.908",
        cop: "11.050.000",
        usa: "7.900",
        details: [
          {
            name: "Valuation consultation by plastic surgeon virtual or face-to-face modality",
            price: "150.000 COP",
          },
          {
            name: "Local anesthesia",
            price: "150.000 COP",
          },
          {
            name: "Botox",
            price: "150.000 COP",
          },
          {
            name: "Post application control consultation of Botox virtual or face-to-face modality",
            price: "600.000 COP",
          },
          {
            name: "Personalized accompaniment",
            price: "600.000 COP",
          },
          {
            name: "VIP Transportation: Private transportation service for land transfers in high-end vehicles with driver and air conditioning",
            price: "200.000 COP",
          },
          {
            name: "Art, playful and personal strengthening workshops",
            price: "200.000 COP",
          },
        ],
      },
      {
        id: "15",
        title: "PLASTIC AND AESTHETIC SURGERY (COLLAGEN TENSIONING THREADS)",
        description:
          "The aesthetic plastic surgery package has the best specialists who provide the service of vaginal rejuvenation, labiaplasty, mammoplasty, tummy tuck, rhinoplasty, liposuction, blepharoplasty, botox, mini cervicoplasty and collagen. Offering the client the best care in integral medicine and satisfaction of their aesthetic needs.",
        image: "/assets/packages/cirugia-plastica.svg",
        usd: "2.908",
        cop: "11.050.000",
        usa: "7.900",
        details: [
          {
            name: "Valuation consultation by plastic surgeon virtual or face-to-face modality",
            price: "150.000 COP",
          },
          {
            name: "Local anesthesia",
            price: "150.000 COP",
          },
          {
            name: "Collagen tension threads",
            price: "150.000 COP",
          },
          {
            name: "Post application control consultation of Botox virtual or face-to-face modality",
            price: "600.000 COP",
          },
          {
            name: "Personalized accompaniment",
            price: "600.000 COP",
          },
          {
            name: "VIP Transportation: Private transportation service for land transfers in high-end vehicles with driver and air conditioning",
            price: "200.000 COP",
          },
          {
            name: "Art, playful and personal strengthening workshops",
            price: "200.000 COP",
          },
        ],
      },
    ],
    countries: [
      {
        id: "0",
        section_title:"Network of Tolimenses",
        see_more:"View more",
        title: "CANADA",
        image: "/assets/flags/canada.png",
        delegates: [
          {
            image: "/assets/delegates/oscar_ortiz.jpg",
            name: "Oscar Ortiz",
            city: "Quevec",
            cel: "3242802435",
            email: "delegados@destinotolima.com",
          },
        ],
      },
      {
        id: "1",
        title: "COLOMBIA",
        image: "/assets/flags/colombia.png",
        delegates: [
          {
            image: "/assets/delegates/carlos_eduardo_barrios.jpg",
            name: "Carlos Eduardo Barrios",
            city: "Bogota",
            cel: "3242802435",
            email: "delegados@destinotolima.com",
          },
        ],
      },
      {
        id: "2",
        title: "UNITED STATES",
        image: "/assets/flags/estados_unidos.png",
        delegates: [
          {
            image: "/assets/delegates/maria_jameson.jpg",
            name: "Maria Jameson",
            city: "Dallas",
            cel: "3242802435",
            email: "delegados@destinotolima.com",
          },
          {
            image: "/assets/delegates/maria_paula_marquez.jpg",
            name: "Maria Paula Marquez",
            city: "Chicago",
            cel: "3242802435",
            email: "delegados@destinotolima.com",
          },
        ],
      },
      {
        id: "3",
        title: "FRANCE",
        image: "/assets/flags/francia.png",
        delegates: [
          {
            image: "/assets/delegates/bibiana_franco.jpg",
            name: "Bibiana Franco",
            city: "Paris",
            cel: "3242802435",
            email: "delegados@destinotolima.com",
          },
        ],
      },
      {
        id: "4",
        title: "ENGLAND",
        image: "/assets/flags/inglaterra.png",
        delegates: [
          {
            image: "/assets/delegates/natalie_bejarano.jpg",
            name: "Natalie Bejarano",
            city: "Liverpool",
            cel: "3242802435",
            email: "delegados@destinotolima.com",
          },
        ],
      },
    ],
    professionals: [
      {
        id: 0,
        section_title: "Health Professionals",
        name: "Dr. ALBERTO BONNET ARCINIEGAS",
        title: "Urology",
        description:
          "Urologist with Certification in Reconstructive Urology. Member of the Colombian Society of Urology and the American Confederation of Urology (CAU)",
        image: "/assets/icono-doctor.png",
        ipsImage1: "/assets/ips/150/SHARON.png",
      },
      {
        id: 1,
        name: "Dra. ALICIA SANDINO NEIRA",
        title: "Reconstructive and aesthetic plastic surgery.",
        description:
          "Participated in the 2013 Resident's Leadership Conference, in Norfolk (VA)",
        image: "/assets/professionals/urocadiz/alicia-sandino.jpeg",
        ipsImage1: "/assets/ips/150/UROCADIZ.png",
      },
      {
        id: 2,
        name: "Dra. ALMA PATRICIA RAMÍREZ CÓRDOBA",
        title: "Radiology",
        description:
          "Radiologist, with training in Breast Diseases Hospital Gregorio Marañón, Spain. Recertified by the Colombian Association of Radiology 2010-2020. ACOMM Certified in Clinical Densitometry 2016-2019, 26-year experience in General Radiology, Breast, CT, Ultrasound, Doppler, Bone Densitometry",
        image: "/assets/professionals/heliscan/alma-patricia-ramirez.jpg",
        ipsImage1: "/assets/ips/150/HELISCAN.png",
        ipsImage2: "/assets/ips/150/OSTEOPOR.png",
      },
      {
        id: 3,
        name: "Dr. ÁLVARO ERNESTO LASSO ACOSTA",
        title: "Orthopedics and Traumatology",
        description:
          "Orthopedics and Traumatology Description: Medical specialist in Orthopedics and Traumatology graduated from the Carlos Chagas Medical Graduation Institute - Federal University of Rio de Janeiro in 2012",
        image: "/assets/icono-doctor.png",
        ipsImage1: "/assets/ips/150/SHARON.png",
      },
      {
        id: 4,
        name: "ANDRES FELIPE VIVAS ANDRADE",
        title: "Diagnostic Imaging",
        description:
          'Medical Radiologist; Health Physics Radio Course - Argentine Society of Radiology -2020. Participation in the Argentine Imaging Congress - August 2019 "findings and usefulness of the dynamic study of CSF in hydrocephalus"',
        image: "/assets/professionals/heliscan/andres-felipe-vivas.jpeg",
        ipsImage1: "/assets/ips/150/HELISCAN.png",
      },
      {
        id: 5,
        name: "Dr. CESAR AUGUSTO ROJAS RODRIGUEZ",
        title: "Urology",
        description:
          "Urologist, with greater performance in areas of endoscopic management of urinary tract lithiasis, prostatic hyperplasia and female urinary incontinence",
        image: "/assets/professionals/urotol/cesar-augusto.png",
        ipsImage1: "/assets/ips/150/UROTOL.png",
      },
      {
        id: 6,
        name: "Dra. CLARA JIMENA ALCAZAR MANRIQUE",
        title: "Plastic, Aesthetic and Reconstructive",
        description:
          "Plastic, aesthetic and reconstructive surgeon with more than 8 years of experience in surgical treatment; with specialized studies in Facial Surgery",
        image: "/assets/icono-doctora.png",
        ipsImage1: "/assets/ips/150/OSTEOPLASTIC.png",
      },
      {
        id: 7,
        name: "Dra. DAISY XIMENA ROA SAAVEDRA",
        title: "Urology",
        description:
          "Urologist, with a penchant for functional urology and endourology, member of the Columbian Society of Urology SCU and active affiliation with the American Association of Urology",
        image: "/assets/professionals/urotol/daisy-ximena.png",
        ipsImage1: "/assets/ips/150/UROTOL.png",
      },
      {
        id: 8,
        name: "Dr. DANIEL FERNANDO TORRES SALAZAR",
        title: "Urology",
        description:
          "Specialist in Urology and Endourology. Member of the Colombian Society of Urology and 31 years of experience",
        image: "/assets/professionals/urocadiz/daniel-fernando-torres.jpeg",
        ipsImage1: "/assets/ips/150/UROCADIZ.png",
      },
      {
        id: 9,
        name: "DANIELA GONZÁLEZ GARZÓN",
        title: "Bacteriologist, Specialist in Epidemiology",
        description:
          "Experience in Molecular Biology, Clinical Laboratory Coordinator with 13 years of experience",
        image: "/assets/professionals/analicemos/daniela-gonzalez.jpeg",
        ipsImage1: "/assets/ips/150/ANALICEMOS.png",
      },
      {
        id: 10,
        name: "DIANA PATRICIA AGUDELO CELIS",
        title: "Surgical instrumentation",
        description:
          "Surgical instrumentalist, creative with strategic vision and marketing of medical products, supplies and devices.",
        image: "/assets/icono-doctora.png",
        ipsImage1: "/assets/ips/150/TRAUMASUR.png",
      },
      {
        id: 11,
        name: "Dr. EDGARDO CABARCAS",
        title: "Orthopedics and traumatology",
        description:
          "Member of the Colombian Society of Orthopedics and Traumatology",
        image: "/assets/icono-doctor.png",
        ipsImage1: "/assets/ips/150/UROCADIZ.png",
      },
      {
        id: 12,
        name: "Dr. FRANCISCO DONALDO MELO AGUILAR",
        title: "Urology",
        description:
          "Specialist in Urology. Member of the Colombian Society of Urology with 15 years of experience",
        image: "/assets/icono-doctor.png",
        ipsImage1: "/assets/ips/150/UROCADIZ.png",
      },
      {
        id: 13,
        name: "Dr. ISRAEL RAMÍREZ",
        title: "Plastic Surgery",
        description:
          "Doctor of Medicine and Surgery, Plastic Surgeon from the Javeriana University of Bogotá with 38 years of experience. Member of the Colombian Society of Maxillofacial and Hand Plastic Surgery",
        image: "/assets/icono-doctor.png",
        ipsImage1: "/assets/ips/150/UNICAT.png",
      },
      {
        id: 14,
        name: "Dr. JAVIER IGNACIO PARDO ARANGO",
        title: "General Surgery",
        description:
          "General Surgeon and Bariatric Surgery, with Certification in Nutritional Support from the Colombian Association of Clinical Nutrition, with 22 years of experience",
        image: "/assets/icono-doctor.png",
        ipsImage1: "/assets/ips/150/SHARON.png",
      },
      {
        id: 15,
        name: "Dr. JHON JAIRO ORTIZ GARCIA",
        title: "Urology",
        description:
          "Specialist in Urology. Member of the Colombian Society of Urology with 16 years of experience",
        image: "/assets/icono-doctor.png",
        ipsImage1: "/assets/ips/150/UROCADIZ.png",
      },
      {
        id: 16,
        name: "Dr. JUAN CARLOS RODRIGUEZ LEONEL",
        title: "Gynecology and obstetrics",
        description:
          "Surgeon specialized in gynecology and obstetrics with 25 years of experience, has a sub-specialist in maternal-fetal medicine and a sub-specialist in reproductive biomedicine",
        image: "/assets/icono-doctor.png",
        ipsImage1: "/assets/ips/150/UNICAT.png",
      },
      {
        id: 17,
        name: "Dr. JUAN DAVID PARRA JIMENEZ",
        title: "Cardiology",
        description:
          "Dr. Parra 9 years of experience as a cardiologist and training in echocardiography from BAS and TEC University of Basic Sciences of Havana Cuba.",
        image: "/assets/icono-doctor.png",
        ipsImage1: "/assets/ips/150/INSTITUTO-CARDIOVASCULAR-DEL-TOLIMA.png",
      },
      {
        id: 18,
        name: "Dr. JUAN FRANCISCO JAVIER OSPINA ORTIZ",
        title: "Orthopedics and Traumatology",
        description:
          "Orthopedist and traumatologist, subspecialist in Foot and Ankle Surgery with more than 7 years of experience in surgical treatments and rehabilitation",
        image: "/assets/icono-doctor.png",
        ipsImage1: "/assets/ips/150/OSTEOPLASTIC.png",
      },
      {
        id: 19,
        name: "Dra. LUCY RAMÍREZ LONDOÑO",
        title: "Dentistry. Oral Oncology and Maxillofacial Prosthesis",
        description:
          "Dentist with 20 years of experience in caring for patients with cancer and other systemic diseases.",
        image: "/assets/professionals/oncomedic/lucy-ramirez.jpeg",
        ipsImage1: "/assets/ips/150/ONCOMEDIC.png",
      },
      {
        id: 20,
        name: "Dr. LUIS ERNESTO OLIVEROS MÉNDEZ",
        title: "Plastic, reconstructive and cosmetic surgery",
        description:
          "Plastic, reconstructive and aesthetic surgeon. Member of the Colombian Society of Aesthetic and Reconstructive Plastic Surgery. Member of the American Association of Surgeons",
        image: "/assets/professionals/medicadiz/luis-ernesto-oliveros.png",
        ipsImage1: "/assets/ips/150/MEDICADIZ.png",
      },
      {
        id: 21,
        name: "Dr. LUIS FERNANDO ZAPATA MADRID",
        title: "Urology",
        description:
          "Urologist. Head of the Urology Service of the Federico Lleras Acosta Departmental Hospital for 20 years, General Secretary of the Colombian Society of Urology for 4 years",
        image: "/assets/professionals/urotol/luis-zapata.png",
        ipsImage1: "/assets/ips/150/UROTOL.png",
      },
      {
        id: 22,
        name: "Dr. MARIO BONNET ARCINIEGAS",
        title: "Urology",
        description:
          "Urologist. Member of the Colombian Society of Urology and the European Society of Urology. Recertified by the Colombian Society of Urology 2021-2023",
        image: "/assets/professionals/urotol/mario-bonnet.png",
        ipsImage1: "/assets/ips/150/UROTOL.png",
      },
      {
        id: 23,
        name: " Dr. MARIO DIDI CRUZ SÁNCHEZ",
        title: "Plastic Surgery",
        description:
          "Plastic Surgeon with training in Microsurgery. Active member of the Colombian Society of Plastic, Aesthetic and Reconstructive Surgery. International Member of the American Society of Plastic Surgeons",
        image: "/assets/icono-doctor.png",
        ipsImage1: "/assets/ips/150/SHARON.png",
      },
      {
        id: 24,
        name: "Dr. MAURICIO ANDRÉS LÓPEZ GUERRERO",
        title: "Urology",
        description:
          "Physician and surgeon, with training in general urology, kidney transplantation, oncological urology, andrology, functional urology, endoscopic, laparoscopic and open surgery",
        image: "/assets/professionals/urotol/mauricio-lopez.png",
        ipsImage1: "/assets/ips/150/UROTOL.png",
      },
      {
        id: 25,
        name: "Dr. MAURICIO HENAO SILVA",
        title: "Internal Medicine and Cardiology.",
        description:
          "Cardiologist. Specialist in Echocardiography from the Universidad del Rosario. 20 years experience",
        image: "/assets/icono-doctor.png",
        ipsImage1: "/assets/ips/150/INSTITUTO-CARDIOVASCULAR-DEL-TOLIMA.png",
      },
      {
        id: 26,
        name: "Dr. NICOLÁS PRADA GARAY",
        title: "Plastic Surgery",
        description:
          "22 years of experience in performing aesthetic and reconstructive plastic surgery",
        image: "/assets/professionals/clinica_tolima/nicolas-prada-garay.jpg",
        ipsImage1: "/assets/ips/150/CLINICA-TOLIMA.png",
      },
      {
        id: 27,
        name: "Dr. OSCAR MAURICIO POVEDA ORTIZ",
        title: "General Surgery.",
        description:
          "General Surgeon- Advanced and Revisional Laparoscopic Surgery. Bariatric Surgery. With 9 years of experience",
        image: "/assets/professionals/clinica_tolima/oscar-mauricio-poveda.jpg",
        ipsImage1: "/assets/ips/150/MEDICADIZ.png",
        ipsImage2: "/assets/ips/150/CLINICA-TOLIMA.png",
      },
      {
        id: 28,
        name: "Dr. VICTOR JOSE GIL ALDANA",
        title: "Cardiology",
        description:
          "Cardiologist. With Sub specialization in Hemodynamics and Interventional Cardiology and Peripheral Vascular. Medical Director Cardiovascular Institute of Tolima",
        image: "/assets/icono-doctor.png",
        ipsImage1: "/assets/ips/150/INSTITUTO-CARDIOVASCULAR-DEL-TOLIMA.png",
      },
      {
        id: 29,
        name: "Dr. ZAMIR ENRIQUE SEGURA PARRA",
        title: "Orthopedics and Traumatology",
        description:
          "Medical specialist in Orthopedics and Traumatology graduated from the Carlos Chagas Medical Graduation Institute - Federal University of Rio de Janeiro in 2012",
        image: "/assets/icono-doctor.png",
        ipsImage1: "/assets/ips/150/SHARON.png",
      },
    ],
    testimonials: [
      {
        id: "0",
        section_title: "Testimonials",
        title: "Luz Henao Velásquez",
        description:
          "Relative of a patient treated at Medicadiz. They recommended Tolima and fell in love with its kindness, facilities and familiarity",
        image: "/assets/testimonials/luz-henao-velasquez.jpg",
        video: "https://www.youtube.com/embed/HSCf_e8bDCQ?rel=0",
      },
      {
        id: "1",
        title: "Valeria Ortegón",
        description:
          "A woman from Bogota who decided to undergo a surgical procedure in Ibagué. The decision was based on quality, service and price.",
        image: "/assets/testimonials/valeria_ortegon.jpg",
        video: "https://www.youtube.com/embed/aHMnvPzmI_I?rel=0",
      },
      {
        id: "2",
        title: "Mercy Collazos",
        description:
          "Experience and result of the Doctor Israel Ramírez. Destination Tolima Health",
        image: "/assets/testimonials/doctor_ismael_ramirez.jpg",
        video: "https://www.youtube.com/embed/0H3Wu5FYLcQ?rel=0",
      },
    ],
    sponsors: [
      {
        id: 0,
        section_title:"Sponsors",
        image: "/assets/sponsors/mincomercio.png",
      },
      {
        id: 1,
        image: "/assets/sponsors/innpulsa.png",
      },
      {
        id: 2,
        image: "/assets/sponsors/cci.png",
      },
      {
        id: 3,
        image: "/assets/sponsors/cluster.png",
      },
      {
        id: 4,
        image: "/assets/sponsors/gobernacion-del-tolima.png",
      },
      {
        id: 5,
        image: "/assets/sponsors/alcaldia-de-ibague.png",
      },
    ],
    menus:{
      start:'Home',
      ips:'IPS',
      professionals:'Professionals',
      packages: 'Packages',
      testimonials: 'Testimonials',
      form: 'Contact'
    },
    banners:[
      {title1:'Perfect ', title2:'weather',description1:'Environmental, cultural, and social for medical interventions. ', description2:'Recover and enjoy an adventure accompanied by sports',btn:'MORE INFORMATION'},
      {copy1:'Our specialists ',copy2:'do the ',copy3:'best ',copy4:'procedure; ',copy5:'your family ',copy6:'does the ',copy7:'best ',copy8:'accompaniment.',btn:'MORE INFORMATION'},
      {copy1:'Check the benefits you have when using our services in one of the best clinics in the country.',btn:'CONTACT FORM'},
      {copy1:'a city where there ', copy2:'is always a plan.', copy3:'Health, recovery, adventure, recreation, sports and business.',btn:'BE SURPRISED'},
    ],
    form:{
      copy1:'The first step to return to Tolima and have the medical procedure you require next to your family and by the hand of a specialist friend',
      copy2:'For more information leave us your information in the form',
      holder1:'Name',
      holder2:'Last Name',
      holder3:'E-mail',
      holder4:'Phone-WhatsApp',
      holder5:'Procedure or treatment of interest',
      btn:'Send',
    }
  },
};

export default { data };